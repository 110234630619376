import React, { useEffect, useState } from 'react';
import { Card, Container } from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Off from "../../assets/img/offers/15.png";
import freeDelivery from "../../assets/img/offers/free-delivery.png";
import free from "../../assets/img/offers/free.png";
import PostApiCall from '../../helper/PostAPI';
import "./Offers.css";
function Offers() {
  const [offerList, setOfferList] = useState([]);
  const offerDiscounts = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  useEffect(() => {
    getOffer()
  }, [])
  const getOffer = async () => {
    PostApiCall.postRequest(
      {
        status: "Active",
      },
      "getOfferList"
    ).then((result) =>
      result?.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          setOfferList(obj.message);
        }
      })
    );
  };

  return (
    <section className="offer-section">
      <Container fluid>
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={offerDiscounts}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={false}
          arrows={false}
          className="pb-4"
        >
          {offerList.map((offer) => {
            var icon = offer.fld_code == "FREEDEL100" ?
              freeDelivery :
              offer.fld_code == "FREEDESSERT" ?
                free : Off
            return (
              <Card className="shadow-sm mr-3 coupon-card position-relative">
                {/* <img src={CouponBg} className='coupon-bg' /> */}
                <Card.Body className="d-flex justify-content-start align-items-center p-2">
                  <div className="left-block">
                    <span className="offer-icon p-2">
                      {/* {offer.fld_code == "MDAY15" && Off}
                      {offer.fld_code == "FREEDEL100" && free}
                      {offer.fld_code == "FREEDESSERT" && freeDelivery} */}
                      <img src={icon} className='img-fluid' />
                    </span>
                  </div>
                  <div className="px-2">
                    <Card.Title className="fw-bold mb-0">{offer.fld_name}</Card.Title>
                    <p>{offer.fld_caption}</p>
                    <Card.Title className="h6 mb-0 mt-2">
                      Use Code: <span className='offer-coupon-code'>{offer.fld_code}</span>
                    </Card.Title>
                  </div>
                </Card.Body>
              </Card>
            )
          })}
        </Carousel>
      </Container>
    </section>
  );
}

export default Offers;